import { useIsMobile } from "@hooks/useIsMobile";
import { Box, Drawer, MenuItem, Select, Switch, TextField, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/CloseRounded';
import CheckIcon from '@mui/icons-material/CheckRounded';
import { useEffect, useState } from "react";
import PrimaryButton from "@components/buttons/PrimaryButton";
import BasicDatePicker from "@ui/dateInput";
import { DateTime } from "luxon";
import BasicTimePicker from "@ui/time-picker";
import moment from "moment-timezone";
import { FormattedMessage } from "react-intl";

export default function EditStatusModal({wo, onClose, options}: any) {

    const isMobile = useIsMobile();

    const [selectedOption, setSelectedOption] = useState<any>({});

    const [showFollowUp, setShowFollowUp] = useState(false);

    const [appointmentType, setAppointmentType] = useState('');
    const [notes, setNotes] = useState('');
    const [holdReason, setHoldReason] = useState('');
    const [cancelReason, setCancelReason] = useState('');
    const [notifyTenant, setNotifyTenant] = useState(false);

    const [errorMessage, setErrorMessage] = useState('');

    const [apptDate, setApptDate] = useState(new Date().toISOString());
    const [apptTime, setApptTime] = useState(new Date().toISOString());

    useEffect(() => {
        if(wo.status) {
            const sP = options.find((op: any) => op.name === wo.status);
            setSelectedOption(sP);
        }
    }, []);

    const onSave = () => {
        const obj: any = {};
        let timeSpent;
        switch(selectedOption.name) {
            case 'Scheduled':
                const date1 = moment(apptDate).format().substring(0, 10);
                const time1 = moment(apptTime).format().substring(11, 19);
                obj.scheduledDate = date1;
                obj.scheduledTime = time1;
                obj.appointment = appointmentType;
                break;
            case 'In Progress':
                const date2 = moment().format().substring(0, 10);
                const time2 = moment().format().substring(11, 19);
                obj.startDate = date2;
                obj.startTime = time2;
                break;
            case 'On Hold':
                timeSpent = moment().diff(
                    moment(new Date(wo.startDate + 'T' + wo.startTime)),
                    'minutes',
                  );
                obj.holdReason = holdReason;
                obj.notes = (wo.notes ? wo.notes + ' ------ ' : '') + notes;
                obj.alreadySpentTime = (wo.alreadySpentTime ?? 0) + timeSpent;
                break;
            case 'Completed':
                const date3 = moment().format().substring(0, 10);
                const time3 = moment().format().substring(11, 19);
                timeSpent = moment().diff(
                    moment(new Date(wo.startDate + 'T' + wo.startTime)),
                    'minutes',
                  );
                obj.completedDate = date3;
                obj.completedTime = time3;
                obj.completedNotes = notes;
                obj.alreadySpentTime = (wo.alreadySpentTime ?? 0) + timeSpent;
                break;
            case 'Cancelled':
                obj.cancellationReason = cancelReason;
                obj.notes = (wo.notes ? wo.notes + ' ------ ' : '') + notes;
                break;
        }
        if(selectedOption.name === 'Completed' && notes === '') {
            setErrorMessage('Please put completion notes');
            return;
        }
        onClose(selectedOption.name, obj, notifyTenant);
    }

    const onUpdate = () => {
        if(selectedOption.name === 'Completed' || selectedOption.name === 'In Progress' || selectedOption.name === 'Closed') {
            if(!wo.assignedToPersonId) {
                alert('Please assign the work order to someone first');
                return;
            }
        }
        setShowFollowUp(true);
    }

    return <>
        <Drawer
            ModalProps={{
                keepMounted: true,
            }}
            anchor={'bottom'}
            sx={{
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                    width: isMobile ? '100%' : '432px',
                    minWidth: '300px',
                    // top: 'unset',
                    boxSizing: 'border-box',
                    border: 'none',
                    // backgroundColor: palette.primary[10],
                    borderTopLeftRadius: 24,
                    borderTopRightRadius: 24,
                    left: 'auto'
                },
                '&.MuiModal-root.MuiDrawer-root': {
                    zIndex: 1300
                }
            }}
            open={true}
            onClose={() => onClose()}
        >
            {!showFollowUp && <Box>
                <Box sx={{display: 'flex', flexDirection: 'row', margin: 2, justifyContent: 'space-between', borderBottom: 'solid 1px #E6ECEF'}}>
                    <Typography sx={{fontSize: '20px', fontWeight: '600', lineHeight: '28px'}}>Select a Status</Typography>
                    <Box sx={{cursor: 'pointer'}} onClick={() => onClose()}><CloseIcon sx={{fontSize: '2rem'}}/></Box>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column', margin: 2, gap: 2}}>
                    {options.map((op: any) => <>
                        <Box sx={{color: op.color, 
                                padding: '12px', 
                                border: `solid 1px ${op.color}`, 
                                borderRadius: '8px', 
                                background: (selectedOption && selectedOption.name === op.name ? op.color : '#fff'),
                                cursor: 'pointer'
                            }} onClick={() => {
                            setSelectedOption(op);
                        }}>
                            <Box sx={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
                                <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', color: selectedOption && selectedOption.name === op.name ? '#fff': op.color}}>
                                    <Box sx={{display: 'flex', flexDirection: 'row', gap: '8px'}}>
                                        {op.icon}
                                        <Typography sx={{fontSize: '16px', fontWeight: selectedOption && selectedOption.name === op.name ? '500' : '400', color: selectedOption && selectedOption.name === op.name ? '#fff': '#000'}}><FormattedMessage id={op.name} /></Typography>
                                    </Box>
                                    {selectedOption && selectedOption.name === op.name && <CheckIcon/>}
                                </Box>
                            </Box>
                        </Box>
                    </>)}
                </Box>
                <Box sx={{ margin: 2, marginTop: 3, marginBottom: 3 }}>
                    <PrimaryButton btnText={<FormattedMessage id="Update Status"/>} onClick={onUpdate}></PrimaryButton>
                </Box>
            </Box>}
            {showFollowUp && <Box>
                <Box sx={{display: 'flex', flexDirection: 'row', margin: 2, justifyContent: 'space-between', borderBottom: 'solid 1px #E6ECEF'}}>
                    <Typography sx={{fontSize: '20px', fontWeight: '600', lineHeight: '28px'}}>
                        {selectedOption.name === 'Scheduled' ? 'Schedule': ''}
                        {selectedOption.name === 'In Progress' ? 'In Progress': ''}
                        {selectedOption.name === 'On Hold' ? 'On Hold': ''}
                        {selectedOption.name === 'Completed' ? 'Completed': ''}
                        {selectedOption.name === 'Cancelled' ? 'Cancelled': ''}
                    </Typography>
                    <Box sx={{cursor: 'pointer'}} onClick={() => onClose()}><CloseIcon sx={{fontSize: '2rem'}}/></Box>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column', margin: 2, gap: 2}}>
                    {selectedOption.name === 'Scheduled' && <>
                        <Box sx={{display: 'flex', gap: '8px', flexDirection: 'column'}}>
                            <Typography>Select Appointment Type</Typography>
                            <Select placeholder='Select an Option' fullWidth value={appointmentType} onChange={(e) => {setAppointmentType(e.target.value);}}>
                                <MenuItem value={"Permission Given"}>Permission to Enter</MenuItem>
                                <MenuItem value={"Call"}>Appointment Required</MenuItem>
                            </Select>
                        </Box>
                       {appointmentType == 'Permission Given' &&<Box sx={{display: 'flex', gap: '8px', flexDirection: 'column'}}>
                            <Typography>Notes</Typography>
                            <TextField multiline={true} minRows={4}
                                placeholder="Notes"
                                maxRows={4}
                                sx={{
                                    "& input": {
                                        backgroundColor: "white",
                                        borderRadius: ".5rem",
                                    },
                                }}
                                value={notes}
                                onChange={(e) => {setNotes(e.target.value);}}></TextField>
                        </Box>}
                        {appointmentType == 'Call' && <Box sx={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
                            <Box>
                                <Typography>Date</Typography>
                                <BasicDatePicker fullWidth value={DateTime.fromISO(apptDate).toLocal()} onChange={(val: any) => setApptDate(val.toString())} />
                            </Box>
                            <Box >
                                <Typography>Appointment Time</Typography>
                                <BasicTimePicker value={DateTime.fromISO(apptTime).toLocal()}
                                    onChange={(value:any) => {
                                        setApptTime(value.toString());
                                    }}/>
                            </Box>
                        </Box>}
                    </>}

                    {selectedOption.name === 'On Hold' && <>
                        <Box sx={{display: 'flex', gap: '8px', flexDirection: 'column'}}>
                            <Typography>Select Reason</Typography>
                            <Select placeholder='Select an Option' fullWidth value={holdReason} onChange={(e) => {setHoldReason(e.target.value);}}>
                                <MenuItem value={"Parts are Needed"}>Parts are Needed</MenuItem>
                                <MenuItem value={"Vendor Services Required"}>Vendor Services Required</MenuItem>
                                <MenuItem value={"Other"}>Other</MenuItem>
                            </Select>
                        </Box>
                       <Box sx={{display: 'flex', gap: '8px', flexDirection: 'column'}}>
                            <Typography>Notes</Typography>
                            <TextField multiline={true} minRows={4}
                                placeholder="Notes"
                                maxRows={4}
                                sx={{
                                    "& input": {
                                        backgroundColor: "white",
                                        borderRadius: ".5rem",
                                    },
                                }}
                                value={notes}
                                onChange={(e) => {setNotes(e.target.value);}}></TextField>
                        </Box>
                    </>}

                    {selectedOption.name === 'Cancelled' && <>
                        <Box sx={{display: 'flex', gap: '8px', flexDirection: 'column'}}>
                            <Typography>Select Reason</Typography>
                            <Select placeholder='Select an Option' fullWidth value={cancelReason} onChange={(e) => {setCancelReason(e.target.value);}}>
                                <MenuItem value={"Tenant Cancelled"}>Tenant Cancelled</MenuItem>
                                <MenuItem value={"Duplicate Work Order"}>Duplicate Work Order</MenuItem>
                                <MenuItem value={"Going to be a Make Ready Item"}>Going to be a Make Ready Item</MenuItem>
                                <MenuItem value={"Other"}>Other</MenuItem>
                            </Select>
                        </Box>
                       <Box sx={{display: 'flex', gap: '8px', flexDirection: 'column'}}>
                            <Typography>Notes</Typography>
                            <TextField multiline={true} minRows={4}
                                placeholder="Cancellation Notes"
                                maxRows={4}
                                sx={{
                                    "& input": {
                                        backgroundColor: "white",
                                        borderRadius: ".5rem",
                                    },
                                }}
                                value={notes}
                                onChange={(e) => {setNotes(e.target.value);}}></TextField>
                        </Box>
                    </>}

                    {selectedOption.name === 'Completed' && <>
                       <Box sx={{display: 'flex', gap: '8px', flexDirection: 'column'}}>
                            <Box sx={{display: 'flex', flexDirection: 'row', gap: 1}}>
                                <Typography>Enter Closing Notes Here</Typography>
                                <Typography sx={{color: 'red'}}>*</Typography>
                            </Box>
                            <TextField multiline={true} minRows={4}
                                placeholder="Completion Notes"
                                maxRows={4}
                                sx={{
                                    "& input": {
                                        backgroundColor: "white",
                                        borderRadius: ".5rem",
                                    },
                                }}
                                value={notes}
                                onChange={(e) => {
                                    setNotes(e.target.value);
                                    if(e.target.value) {
                                        setErrorMessage('');
                                    }
                                }}></TextField>
                            <Typography sx={{fontSize: '14px', color: 'red'}}>{errorMessage}</Typography>
                        </Box>
                    </>}

                    {selectedOption.name === 'In Progress' && <>
                       <Box sx={{display: 'flex', gap: '8px', flexDirection: 'column'}}>
                            <Typography>Notify Tenant</Typography>
                            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '16px'}}>
                                <Typography>Send a automated text message that their work order is now in progress</Typography>
                                <Switch value={notifyTenant} onChange={(e, checked) => setNotifyTenant(checked)}/>
                            </Box>
                        </Box>
                    </>}
                </Box>
                <Box sx={{ margin: 2, marginTop: 3, marginBottom: 3 }}>
                    <PrimaryButton btnText={<FormattedMessage id="Update Status"/>} onClick={onSave}></PrimaryButton>
                </Box>
            </Box>}
        </Drawer>
    </>

}