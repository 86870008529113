import { Box, Grid, MenuItem, Stack, Typography } from "@mui/material";
import { Control, Controller, FieldErrors, useForm } from "react-hook-form";
import { FormInputField, FormSelectField } from "@ui/form";
import PrimaryButton from "@components/buttons/PrimaryButton";
import palette from "@utils/palette";
import { useAuth } from "@contexts/AuthContext";
import { useState } from "react";

interface ITenantIssue {
    control: Control<any>;
    errors: FieldErrors<any>;
    onNext: () => void;
    setValue: any;
    isValid: boolean;
    units?: any;
}

export default function PreventiveWork({ setValue, control, errors, onNext, isValid, units }: ITenantIssue) {
    const {user} = useAuth();

    const [selectedCategory, setSelectedCategory] = useState(null as any);
    const onCategorySelect = (e:any) => {
        setValue('category', e.target.value);
        setSelectedCategory(e.target.value);
    }
    return <>
        <Stack sx={{ mt: 3, mb: 12, backgroundColor: palette.white, px: 3, py: 20 / 8 }}>
            <Typography variant="body1" sx={{ fontWeight: 500, mb: 3, color: '#5A6A72' }}>Preventive Work</Typography>
            <Grid container rowSpacing={3}>
                <Grid item xs={12}>
                    <Controller
                        name="category"
                        control={control}
                        rules={{
                            required: "Category is required.",
                        }}
                        render={({ field }) => {
                            return (
                                <FormSelectField
                                    label="Category"
                                    {...field}
                                    error={errors?.category}
                                    helperText={errors?.category?.message}
                                    onChange={onCategorySelect}
                                >
                                    {user?.categories?.filter((c) => c.name == 'Preventative Maintenance').map(cat => <MenuItem value={cat.id}>{cat.name}</MenuItem>)}
                                </FormSelectField>
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="issueType"
                        control={control}
                        rules={{
                            required: "Issue Type is required.",
                        }}
                        render={({ field }) => {
                            return (
                                <FormSelectField
                                    label="Issue Type"
                                    {...field}
                                    error={errors?.issueType}
                                    helperText={errors?.issueType?.message}
                                >
                                    {user?.categories?.find(c => c.id === selectedCategory)?.subCategories.map((cat: any) => <MenuItem value={cat.id}>{cat.name}</MenuItem>)}
                                </FormSelectField>
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="unit"
                        control={control}
                        rules={{
                            required: "Location is required.",
                        }}
                        render={({ field }) => {
                            return (
                                <FormSelectField
                                    label="Location"
                                    {...field}
                                    error={errors?.unit}
                                    helperText={errors?.unit?.message}
                                >
                                    {/* {units && Object.keys(units).map((type) => <> */}
                                        {units && units.map((unit: any) => <MenuItem value={unit.name}>{unit.locationType} {unit.name}</MenuItem>)}
                                    {/* </>)} */}
                                </FormSelectField>
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="priority"
                        control={control}
                        rules={{
                            required: "Priority is required.",
                        }}
                        render={({ field }) => {
                            return (
                                <FormSelectField
                                    label="Priority"
                                    {...field}
                                    error={errors?.priority}
                                    helperText={errors?.priority?.message}
                                >
                                    <MenuItem value={"High"}>High</MenuItem>
                                    <MenuItem value={"Medium"}>Medium</MenuItem>
                                    <MenuItem value={"Low"}>Low</MenuItem>
                                    <MenuItem value={"No Priority"}>No Priority</MenuItem>
                                </FormSelectField>
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="description"
                        control={control}
                        rules={{
                            required: "Description is required.",
                        }}
                        render={({ field }) => {
                            return (
                                <FormInputField
                                    multiline
                                    minRows={4}
                                    maxRows={4}
                                    label="Description"
                                    {...field}
                                    error={errors?.description}
                                    helperText={errors?.description?.message}
                                />
                            );
                        }}
                    />
                </Grid>
            </Grid>
        </Stack>
        <Stack sx={{ backgroundColor: palette.white, px: 3, py: 20 / 8, marginTop: 'auto', position: 'fixed', bottom: 0, width: '100%', maxWidth: '432px' }}>
            <Box sx={{  }}>
                <PrimaryButton isDisabled={!isValid} isFullWidth={true} onClick={onNext} btnText="Continue" />
            </Box>
        </Stack>
    </>
}