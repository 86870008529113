import { PRIORITY_TYPE } from './models';

export const getInitials = (string: string) => {
  var names = (string ?? '').split(' '),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export const getRandomColor = () => {
  const random: number = Math.floor(Math.random() * (10 - 1 + 1) + 1);
  switch (random) {
    case 1:
      return '#F93E3E';
    case 2:
      return '#F9703E';
    case 3:
      return '#05E15D';
    case 4:
      return '#1565FF';
    case 5:
      return '#B71FFE';
    case 6:
      return '#00DCEA';
    case 7:
      return '#7D19FE';
    case 8:
      return '#FF11D9';
    case 9:
      return '#884100';
    case 10:
      return '#E5CF00';
    default:
      return '#F93E3E';
  }
};

export const getColorByText = (text: string) => {
  let sum = 0;
  Array.from(text ?? '').forEach((c: string)=>{
    sum += c.charCodeAt(0);
  });
  const random = (Number(sum.toString()[sum.toString().length - 1]) || 0) + 1;
  switch (random) {
    case 1:
      return '#F93E3E';
    case 2:
      return '#F9703E';
    case 3:
      return '#05E15D';
    case 4:
      return '#1565FF';
    case 5:
      return '#B71FFE';
    case 6:
      return '#00DCEA';
    case 7:
      return '#7D19FE';
    case 8:
      return '#FF11D9';
    case 9:
      return '#884100';
    case 10:
      return '#E5CF00';
    default:
      return '#F93E3E';
  }
}

export const getPriorityColor = (type: PRIORITY_TYPE) => {
  switch (type) {
    case PRIORITY_TYPE.HIGH:
      return '#CB6E17';
    case PRIORITY_TYPE.MEDIUM:
      return '#3A86CB';
    case PRIORITY_TYPE.LOW:
      return '#8D9DA5';
    default:
      return '#2B363B';
  }
};

export const getPriorityBgColor = (type: PRIORITY_TYPE) => {
  switch (type) {
    case PRIORITY_TYPE.HIGH:
      return '#FFF3C4';
    case PRIORITY_TYPE.MEDIUM:
      return '#CEE6FF';
    case PRIORITY_TYPE.LOW:
      return '#EDF1F3';
    default:
      return '#FFF';
  }
};

export const getTechPriorityColor = (type: PRIORITY_TYPE) => {
  switch (type) {
    case PRIORITY_TYPE.HIGH:
      return { bg: '#FFE0E0', color: '#AB091E' };
    case PRIORITY_TYPE.MEDIUM:
      return { bg: '#FFF3C4', color: '#B44D12' };
    case PRIORITY_TYPE.LOW:
      return { bg: '#CEE6FF', color: '#3478B7' };
    default:
      return { bg: '#2B363B', color: '#0000' };
  }
};

export const groupBy = (list: any[], keyGetter: Function) => {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return Array.from(map, ([key, value]) => ({ key: key, value }));;
}

export const makeReadyTasks = [
  {name: 'Pre MoveOut Inspection', description: 'Perform a premove out inspection unit prior to move out to determine what items need to be completed'},
  {name: 'MoveOut Inspection', description: 'Perform Move Out inspection and create punch list items necessary to make unit Rent Ready.  Chargeback resident as appropriate for any damages'},
  {name: 'Trash', description: 'Remove all trash and debris.  Maintain personal items as required.'},
  {name: 'Pest Control', description: 'Pest Control'},
  {name: 'Maintenance', description: 'Complete all standard make ready items and any punch list items noted during the move out inspection'},
  {name: 'Paint', description: 'Paint unit for renovation as per renovation spec'},
  {name: 'Resurface', description: 'Resurface Countertops/ Shower as needed'},
  {name: 'Flooring', description: 'Replace Flooring in unit for renovation as per renovation spec'},
  {name: 'Final Clean', description: 'Final Clean unit as per renovation spec'},
  {name: 'Patio Clean', description: 'Clean the exterior patio'},
  {name: 'Quality Inspection', description: 'Perform Quality Inspection.  Created a punch work order for any noted items as necessary.   If accepted make unit Rent Ready'},
]

export const getTitle = (description: string) => {
  switch(description) {
    case 'Perform Quality Inspection.  Created a punch work order for any noted items as necessary.   If accepted make unit Rent Ready': 
      return 'Quality Inspection';
    case 'Perform a premove out inspection unit prior to move out to determine what items need to be completed':
      return 'Pre MoveOut Inspection';
    case 'Clean the exterior patio':
      return 'Patio Clean';
    case 'Final Clean unit as per renovation spec':
      return 'Final Clean';
    case 'Replace Flooring in unit for renovation as per renovation spec':
      return 'Flooring';
    case 'Resurface Countertops/ Shower as needed':
      return 'Resurface';
    case 'Paint unit for renovation as per renovation spec':
      return 'Paint';
    case 'Complete all standard make ready items and any punch list items noted during the move out inspection':
      return 'Maintenance';
    case 'Remove all trash and debris.  Maintain personal items as required.':
      return 'Trash';
    case 'Pest Control':
      return 'Pest Control';
    case 'Perform Move Out inspection and create punch list items necessary to make unit Rent Ready.  Chargeback resident as appropriate for any damages':
      return 'MoveOut Inspection';
    default:
      return description;
  }
}

export const getTitleSeq = (description: string) => {
  switch(description) {
    case 'Perform a premove out inspection unit prior to move out to determine what items need to be completed':
      return 1;
    case 'Perform Move Out inspection and create punch list items necessary to make unit Rent Ready.  Chargeback resident as appropriate for any damages':
      return 2;
    case 'Remove all trash and debris.  Maintain personal items as required.':
      return 3;
    case 'Pest Control':
      return 4;
    case 'Complete all standard make ready items and any punch list items noted during the move out inspection':
      return 5;
    case 'Paint unit for renovation as per renovation spec':
      return 6;
    case 'Resurface Countertops/ Shower as needed':
      return 7;
    case 'Replace Flooring in unit for renovation as per renovation spec':
      return 8;
    case 'Final Clean unit as per renovation spec':
      return 9
    case 'Clean the exterior patio':
      return 10;
    case 'Perform Quality Inspection.  Created a punch work order for any noted items as necessary.   If accepted make unit Rent Ready': 
      return 11;
    default:
      return 0;
  }
}

export const sortMRRows = (rows: any[]) => {
  const arr = [];
  let ct = 1;
  for(const row of rows) {
    let seq = getTitleSeq(row.description)
    if(seq === 0) {
      seq = 11 + ct;
      ct += 1;
    }
    arr.push({id: seq, row: row});
  }
  arr.sort((a1: any, a2: any) => a1.id - a2.id);
  return arr.map(a => a.row);
}

export const priorityOptions = [
  {
    id: '2',
    name: PRIORITY_TYPE.HIGH,
    description:
      'Resolve promptly to prevent inconvenience for the tenant and avoid escalation.',
    color: getPriorityColor(PRIORITY_TYPE.HIGH),
    background: getPriorityBgColor(PRIORITY_TYPE.HIGH),
  },
  {
    id: '3',
    name: PRIORITY_TYPE.MEDIUM,
    description:
      'If neglected, issue has the potential to affect the tenants life.',
    color: getPriorityColor(PRIORITY_TYPE.MEDIUM),
    background: getPriorityBgColor(PRIORITY_TYPE.MEDIUM),
  },
  {
    id: '4',
    name: PRIORITY_TYPE.LOW,
    description: 'Non-urgent matters or routine maintenance.',
    color: getPriorityColor(PRIORITY_TYPE.LOW),
    background: getPriorityBgColor(PRIORITY_TYPE.LOW),
  },
  {
    id: '5',
    name: PRIORITY_TYPE.NO,
    description: '',
    color: getPriorityColor(PRIORITY_TYPE.NO),
    background: getPriorityBgColor(PRIORITY_TYPE.NO),
  }
];

export  const statusOptions = [
  {
    id: 0,
    name: 'Unassigned',
    color: '#9229A3',
    background: '#E9C2F0'
  },
  {
    id: 1,
    name: 'Not Started',
    color: '#9229A3',
    background: '#E9C2F0'
  },
  {
    id: 7,
    name: 'Scheduled',
    color: '#9229A3',
    background: '#E9C2F0',
  },
  {
    id: 2,
    name: 'In Progress',
    color: '#3478B7',
    background: '#CEE6FF'
  },
  {
    id: 3,
    name: 'On Hold',
    color: '#F9703E',
    background: '#FFE8D9',
  },
  {
    id: 5,
    name: 'Closed',
    color: '#217345',
    background: '#D6F9E5',
  },
  {
    id: 4,
    name: 'Completed',
    color: '#217345',
    background: '#D6F9E5',
  },
 
  {
    id: 6,
    name: 'Cancelled',
    color: '#AB091E',
    background: '#FFE0E0',
  },
];


export enum DrawerType {
  PropertyFilter = 'PropertyFilter'
}

export const getDrawerType = (field: any) => {
  return DrawerType.PropertyFilter
}

export const getCellStyle = (cell: string, value: any, isMobile = false) => {
  const styles: any = {border: 0, py: 1, borderBottom: '0.5px solid #fff'};
  switch(cell) {
    case 'progress':
      const completedCount = value.filter((w: any) => w.status === 'Completed').length;
      if(completedCount === 0) {
        styles.color = '#9229A3';
        styles.background = '#E9C2F0';
      } else {
        styles.color = '#3478B7';
        styles.background = '#CEE6FF';
      }
      break;
    case 'vacancyStatus':
      if(value === 'Notice to Vacate Pre-Leased') {
        styles.color = '#9229A3';
        styles.background = '#E9C2F0';
      } else if(value === 'Vacant Pre-Leased') {
        styles.color = '#AB091E';
        styles.background = '#FFE0E0';
      } else if(value === 'Notice to Vacate') {
        styles.color = '#3478B7';
        styles.background = '#CEE6FF';
      } else if(value === 'Vacant') {
        styles.color = '#217345';
        styles.background = '#D6F9E5';
      }
  }
  if(isMobile) {
    styles.fontSize = '12px';
    styles.lineHeight = '14px';
    styles.p = 0.5;
    styles.borderRadius = '8px';
  }
  return styles;
}



export  const mrStatusOptions = [
  {
    id: 0,
    name: 'Vacant',
    color: '#9229A3',
    background: '#E9C2F0'
  },
  {
    id: 1,
    name: 'Notice to Vacate Pre-Leased',
    color: '#9229A3',
    background: '#E9C2F0'
  },
  {
    id: 7,
    name: 'Vacant Pre-Leased',
    color: '#9229A3',
    background: '#E9C2F0',
  },
  {
    id: 2,
    name: 'Notice to Vacate	',
    color: '#3478B7',
    background: '#CEE6FF'
  }
];

export const getRole = (user: any) => {
  const role = user.role ?? user.roles[0].role;
  switch(role) {
    case 'admin':
      return 'Admin';
    case 'pm':
      return 'Property Manager';
    case 'rm':
      return 'Regional Manager';
    case 'rmd':
      return 'Regional Maintenance Director';
    case 'tech':
      return 'Maintenance Technician';
    case 'supervisor':
      return 'Maintenance Supervisor';
    case 'construction':
      return 'Construction';
    case 'leasing':
      return 'Leasing Agent';
    case 'vp':
      return 'Vice President';
    case 'owner':
      return 'Owner';
    case 'superadmin':
      return 'Super Admin';
    case 'procurement':
      return 'Procurement';
    case 'centralops':
      return 'Central Ops';
    case 'am':
      return 'Asset Manager';
    case 'learning':
      return 'Learning';
  }
}

export const getWOAreas = (areas: any[]) => {
  const woAreas: any[] = [];
  for(const area of areas ?? []) {
    let woArea = '';
    switch(area) {
      case 'Bathroom 1':
        woArea = '0';
        break;
      case 'Bathroom 2':
        woArea = '1';
        break;
      case 'Bedroom 1':
        woArea = '2';
        break;
      case 'Bedroom 2':
        woArea = '3';
        break;
      case 'Bedroom 3':
        woArea = '4';
        break;
      case 'Kitchen':
        woArea = '5';
        break;
      case 'Hallway':
        woArea = '6';
        break;
      case 'Living Room':
        woArea = '7';
        break;
      case 'Patio':
        woArea = '8';
        break;
    }
    woAreas.push(woArea);
  }
  console.log(woAreas);
  return woAreas;
}

export const getGDAreas = (areas: any[]) => {
  const gdAreas: any[] = [];
  for(const area of areas ?? []) {
    let gdArea = '';
    switch(area) {
      case '0':
        gdArea = 'Bathroom 1';
        break;
      case '1':
        gdArea = 'Bathroom 2';
        break;
      case '2':
        gdArea = 'Bedroom 1';
        break;
      case '3':
        gdArea = 'Bedroom 2';
        break;
      case '4':
        gdArea = 'Bedroom 3';
        break;
      case '5':
        gdArea = 'Kitchen';
        break;
      case '6':
        gdArea = 'Hallway';
        break;
      case '7':
        gdArea = 'Living Room';
        break;
      case '8':
        gdArea = 'Patio';
        break;
    }
    gdAreas.push(gdArea);
  }
  console.log(gdAreas);
  return gdAreas;
}

export const getWorkOrdersForUnits = (workOrders: any[], units: any[]) => {
  const finalList: any[] = [];
  const unitsWorkOrders: any = {};
  for(const workOrder of workOrders ?? []) {
    if(workOrder.status !== 'Cancelled') {
      const unit = workOrder.unit;
      if(!unitsWorkOrders[unit]) {
        unitsWorkOrders[unit] = [workOrder];
      } else {
          unitsWorkOrders[unit].push(workOrder);
      }
    }
  }
  for(const unit in unitsWorkOrders) {
    if(unitsWorkOrders[unit].length > 0) {
      unitsWorkOrders[unit].sort((a: any, b: any) => {
        return a.reportedDate < b.reportedDate ? 1 : a.reportedDate === b.reportedDate ? a.reportedTime < b.reportedTime ? 1 : -1 : -1;
      });
      makeReadyTasks.forEach((task: any) => {
        const found = unitsWorkOrders[unit].find((w: any) => w.description.toLowerCase().replaceAll(' ', '') === task.description.toLowerCase().replaceAll(' ', ''));
        if(found) {
          const unit = units.find((u: any) => u.unitNumber === found.unit);
          if(unit){
            if(unit.pMoveIn) {
              if(found.reportedDate >= unit.pMoveIn) {
                finalList.push(found);
              }
            } else {
              finalList.push(found);
            }
          }
        }
      });
      // finalList.push(...unitsWorkOrders[unit]);
    }
  }
  return finalList;
}