import { useIsMobile, useIsTab } from '@hooks/useIsMobile';
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Stack
} from '@mui/material';
import { ReactNode, cloneElement } from 'react';
import palette from '@utils/palette';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { getColorByText } from '@utils/helpers';
import { IconContainer } from './sidebar';
import { ROOT_ROUTES } from '@utils/routes';
import { useNavigate } from 'react-router-dom';
import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded';

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
}

interface IProps {
  title: any;
  action?: ReactNode;
  selection?: ReactNode;
  bottom?: ReactNode;
  secondTitle?: any;
  name?: string;
  mobileAction?: ReactNode;
}

export default function PageHeader({
  title,
  action,
  selection,
  bottom,
  secondTitle,
  name,
  mobileAction
}: IProps) {
  const isMobile = useIsMobile();
  const isTab = useIsTab();
  const navigate = useNavigate();

  return (
    <Box
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: isMobile ? 'flex-start' : 'center',
        justifyContent: 'space-between',
        marginTop: '.1rem',
        marginBottom: '2rem'
      }}
    >
      {isMobile && (
          <AppBar
            position="fixed"
            color="secondary"
            sx={{
              zIndex: 1300,
              top: 0,
              bottom: 'auto'
            }}
          >
            <Toolbar>
              <Box sx={{ width: '100%', py: 1, flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Stack direction="row" spacing={1.5}>
                  {name && <Stack justifyContent="center" alignItems="center" sx={{ height: '3.25rem', width: '3.25rem', backgroundColor: getColorByText(title), borderRadius: '50%' }}>
                    <Typography variant="h4" sx={{ color: palette.white }}>{name?.toUpperCase()}</Typography>
                  </Stack>}
                  <Stack justifyContent="space-between">
                    {secondTitle && <Typography variant="body2" sx={{ mb: .5, color: '#5A6A72' }}>{secondTitle}</Typography>}
                    <Typography
                      variant="h4"
                      noWrap
                      sx={{ flexGrow: 1 }}
                      component="div"
                    >
                      {title}
                    </Typography>
                  </Stack>
                </Stack>
                {mobileAction && mobileAction}
                <IconContainer
                  active={location.pathname === ROOT_ROUTES.NOTIFICATIONS}
                  onClick={() => navigate(ROOT_ROUTES.NOTIFICATIONS)}
                >
                  <NotificationsNoneRoundedIcon sx={{color: '#000'}} />
                </IconContainer>
              </Box>
            </Toolbar>
            {bottom && bottom}
          </AppBar>
      )}
      {!isMobile && (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: isTab ? 'column' : 'row',
              alignItems: 'center',
              gap: '0.5rem'
            }}
          >
            <Stack direction="row" spacing={1.5} alignItems="center">
              {name && <Stack justifyContent="center" alignItems="center" sx={{ height: '3.25rem', width: '3.25rem', backgroundColor: '#F9703E', borderRadius: '50%' }}>
                <Typography variant="h4" sx={{ color: palette.white }}>{name?.toUpperCase()}</Typography>
              </Stack>}
              {secondTitle && <Typography variant="h5" sx={{ mb: .5, color: '#5A6A72' }}>{secondTitle}</Typography>}
              <Typography
                variant="h5"
                noWrap
                sx={{ flexGrow: 1 }}
                component="div"
              >
                {title}
              </Typography>
            </Stack>
            {selection && !isTab && (
              <Box
                sx={{
                  width: '1px',
                  height: '30px',
                  bgcolor: '#30343657',
                  alignItems: 'center'
                }}
              ></Box>
            )}
            {selection && <Box sx={{ pb: isMobile ? 2 : 0 }}>{selection}</Box>}
          </Box>
          <Box sx={{display: 'flex'}}>
            {action && action}
            <IconContainer
              active={location.pathname === ROOT_ROUTES.NOTIFICATIONS}
              onClick={() => navigate(ROOT_ROUTES.NOTIFICATIONS)}
            >
              <NotificationsNoneRoundedIcon sx={{color: '#000'}} />
            </IconContainer>
          </Box>
        </>
      )}
    </Box>
  );
}
