import AuthLayout from '@components/layouts/AuthLayout';
import { useIsMobile } from '@hooks/useIsMobile';
import { Alert, Box, ButtonBase, Grid, Paper, Stack, styled, Typography } from '@mui/material';
import { ReactComponent as GoogleLogo } from '@assets/images/google_logo.svg';
import { useAuth } from '@contexts/AuthContext';
import { useState } from 'react';
import { signInWithGoogle } from 'src/firebase';
import { LoaderIcon } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import TextInput from '@components/inputs/TextInput';
import { EMAIL_REGEX } from '@utils/constants';
import PrimaryButton from '@components/buttons/PrimaryButton';
import EmailIcon from '@mui/icons-material/Email';
import HttpsIcon from '@mui/icons-material/Https';

interface IFormInput {
  email: string;
  password: string;
}

const SignInButton = styled(ButtonBase)(() => ({
  padding: '1rem 1.25rem',
  borderRadius: '.5rem',
  border: `1px solid #27293759`,
  width: '100%',
  maxWidth: '26rem',
  fontSize: '1rem',
  lineHeight: 1.5,
  fontWeight: 600,
  display: 'flex',
  alignItems: 'center',
  gap: '.625rem',
}));

export default function LoginPage() {
  const { login, login2 } = useAuth();
  const isMobile = useIsMobile();
  const [isLoading, setIsLoading] = useState(false);

  const [errorMsg, setErrorMsg] = useState('');

  const [showForm, setShowForm] = useState(false);

  const { control, handleSubmit, formState, setError } = useForm<IFormInput>({
    defaultValues: { email: '', password: '' },
    mode: 'onChange'
  });

  const onSubmit = async ({ email, password }: IFormInput) => {
    try {
      const res = await login2(email, password);
    } catch (err: any) {
      setErrorMsg('Username or Password Incorrect')
    }
  };

  const onClick = async () => {
    setIsLoading(true);
    const res = await signInWithGoogle();
    const token = await res.user.getIdToken();
    await login(token, res.user?.email);
    setIsLoading(false);
  };


  return (
    <AuthLayout pageTitle="Log In">
      <Paper
        sx={{
          width: isMobile ? '100%' : '39.5rem',
          px: isMobile ? '1rem' : '7rem',
          py: '1rem',
          borderRadius: '16px',
          boxShadow: '0px 10px 40px 2px #2173451F'
        }}
      >
        <Box py={4}>
          <Typography
            variant={isMobile ? 'h4' : 'h3'}
            mb="1rem"
            textAlign={'center'}
          >
            Welcome Back
          </Typography>
        </Box>
        {!isLoading && <Grid item xs={6} sx={{ height: '100%', px: 2 }}>
          {!showForm ? 
            <>
              <Stack justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
                <SignInButton onClick={onClick}>
                  <GoogleLogo /> Sign in with Google
                </SignInButton>
              </Stack>
              <Stack justifyContent="center" alignItems="center" sx={{ marginTop: 2 }}>
                <Typography sx={{fontSize: '14px'}}>{'Not a Resprop Employee? '}
                  <a href='javascript:void(0)' onClick={() => setShowForm(true)}>Login with email and password</a></Typography>
                {/* <Typography variant="body2" color="textSecondary" mt={1}>
                  If you are a Resprop employee and cannot login, please contact your administrator.
                </Typography> */} 
              </Stack>
            </>:
            <>
              <Stack justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ marginBottom: '2rem' }}>
                  <TextInput
                    name="email"
                    label="Email"
                    placeholder="Enter Your Email Address"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Email is required'
                      },
                      maxLength: {
                        value: 50,
                        message: 'Email is too long'
                      },
                      pattern: {
                        value: EMAIL_REGEX,
                        message: 'Email is invalid'
                      }
                    }}
                    type="email"
                    isSubmitFocus={true}
                    startIcon={<EmailIcon />}
                  />
                  <TextInput
                    name="password"
                    label="Password"
                    placeholder="Enter Your Password"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Password is required'
                      }
                    }}
                    type="password"
                    isSubmitFocus={true}
                    startIcon={<HttpsIcon />}
                  />
                </Box>
                <Box textAlign="center">
                  <PrimaryButton
                    btnText="Sign In"
                    formState={formState}
                    isFullWidth
                  />
                </Box>
              </form>
              </Stack>
            </>
          }
        </Grid>}
        {isLoading && <Box sx={{display: 'flex', minHeight: '200px', alignItems: 'center', justifyContent: 'center' }}><LoaderIcon/></Box>}
        {localStorage.getItem('error') && <Typography color={'red'} mt="1rem" textAlign={'center'}>{localStorage.getItem('error')}</Typography>}
      </Paper>
      {errorMsg && <Alert severity='error' onClose={() => {setErrorMsg('')}}>{errorMsg}</Alert>}
    </AuthLayout>
  );
}
