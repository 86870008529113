import { Button, Drawer, Grid, MenuItem, Stack, Typography } from "@mui/material";
import { ButtonCover } from "@ui/button";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import palette from "@utils/palette";
import { Controller, useForm } from "react-hook-form";
import { FormInputField, FormSelectField } from "@ui/form";
import { useAuth } from "@contexts/AuthContext";
import { useEffect, useMemo } from "react";
import { postRequest, putRequest } from "@utils/http.service";

export default function CreateUpdatePopup({selectedWorkflow, handleClose}: any) {
    const {user} = useAuth();

    const categories = useMemo(() => {
        const allCategories: any[] = [];
        user?.categories?.forEach((category: any) => {
            category.subCategories.forEach((subCategory: any) => {
                allCategories.push(subCategory);
            });
        })
        return allCategories;
    }, [user]);
    const {
        control,
        formState: { errors, isValid },
        setValue,
        handleSubmit,
        reset,
    } = useForm({
        defaultValues: {
            name: "",
            categories: [],
            steps: [],
            priority: 0,
        },
        mode: "onTouched",
    });

    useEffect(() => {
        if (selectedWorkflow) {
            const { name, categories, steps, priority } = selectedWorkflow;
            reset({ name, categories, steps: JSON.parse(steps), priority });
        }categories
    }, [user]);

    const saveWorkflow = async(data: any) => {
        if(selectedWorkflow) {
            await putRequest(`workflows/${selectedWorkflow.id}`, {...data, steps: JSON.stringify(data.steps)}, {
                headers: {
                    Authorization: `Bearer ${user!!.token}`
                }
            });
        } else {
            await postRequest(`workflows`, {...data, steps: JSON.stringify(data.steps)}, {
                headers: {
                    Authorization: `Bearer ${user!!.token}`
                }
            });
        }
        handleClose(true);
    }


    return (
        <Drawer
            anchor={'right'}
            open={true}
            sx={{
                // zIndex: '1400 !important',
                '& .MuiPaper-root': {
                    width: 640,
                    maxWidth: 640,
                    backgroundColor: '#F5F8FA'
                }
            }}
        >
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ py: 2.5, px: 3, boxShadow: '0px .125rem .5rem 0px rgba(48, 52, 54, 0.08)', backgroundColor: palette.white }}>
                <ButtonCover onClick={() => handleClose(false)}>
                    <CloseRoundedIcon sx={{ color: '#303436bf', width: '2rem', height: '2rem' }} />
                </ButtonCover>
                <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.2, fontWeight: 400, color: '#5A6A72' }}>{selectedWorkflow ? 'Edit' : 'Add'} Workflow</Typography>
                <Button></Button>
            </Stack>
            <Stack sx={{ mt: 3, backgroundColor: palette.white, px: 3 }}>
                <Grid container columnSpacing={3} rowSpacing={1}>
                    <Grid item xs={12}>
                        <Controller
                            name="name"
                            control={control}
                            rules={{
                                required: "First Name is required.",
                            }}
                            render={({ field }) => {
                                return (
                                    <FormInputField
                                        label="Name"
                                        placeholder="Name"
                                        {...field}
                                        error={errors?.name}
                                        helperText={errors?.name?.message}
                                    />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="categories"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <FormSelectField
                                        label="Categories"
                                        {...field}
                                        error={errors?.categories}
                                        helperText={errors?.categories?.message}
                                        multiple
                                    >
                                        {categories.map((subCategory: any) => (<MenuItem value={subCategory.id}>{subCategory.name}</MenuItem>))}
                                    </FormSelectField>
                                );
                            }}
                        />
                    </Grid>
                </Grid>
                <Stack direction="row" spacing={3} justifyContent="flex-end" sx={{ my: 7 }}>
                    <Button variant="outlined"  onClick={() => {handleClose(false)}}>Cancel</Button>
                    <Button variant="contained"  sx={{ color: palette.white }} onClick={handleSubmit(saveWorkflow)}>Save Changes</Button>
                </Stack>
            </Stack>
        </Drawer>
    );
}