import PageHeader from "@components/PageHeader";
import { useAuth } from "@contexts/AuthContext";
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { ButtonCover } from "@ui/button";
import { FormattedMessage } from "react-intl";
import MoreHorizIcon from '@mui/icons-material/Edit';
import { useMemo, useState } from "react";
import CreateUpdatePopup from "@components/workflow/CreateUpdatePopup";
import PrimaryButton from "@components/buttons/PrimaryButton";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export default function WorkFlowsPage() {

    const {user} = useAuth();
    const [selectedWorkflow, setSelectedWorkflow] = useState<any>(null);
    const [showPopup, setShowPopup] = useState(false);

    const categories = useMemo(() => {
        const allCategories: any[] = [];
        user?.categories?.forEach((category: any) => {
            category.subCategories.forEach((subCategory: any) => {
                allCategories.push(subCategory);
            });
        })
        return allCategories;
    }, [user]);

    return (<>
        <Box>
            <PageHeader
              title={<FormattedMessage id="Manage Workflows" />}
              action={
                <PrimaryButton
                    onClick={() => setShowPopup(true)}
                    btnText={<FormattedMessage id="Add New Workflow"/>}
                    isSmall
                    startIcon={<AddCircleOutlineIcon />}
                    style={{ p: '8px 8px' }}
                />
          }
            />
            <TableContainer
                component={Paper}
                sx={{
                    boxShadow: 'rgba(48, 52, 54, 0.1) 0px 6px 24px 0px',
                    borderRadius: '1rem',
                    mt: 5,
                    mb: 2
                }}
            >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                sx={{
                                borderBottom: '1px solid #EDF1F3',
                                color: '#8D9DA5'
                                }}
                            >
                                Name
                            </TableCell>
                            <TableCell
                                sx={{
                                borderBottom: '1px solid #EDF1F3',
                                color: '#8D9DA5'
                                }}
                            >
                                Categories
                            </TableCell>
                            {/* <TableCell
                                sx={{
                                borderBottom: '1px solid #EDF1F3',
                                color: '#8D9DA5'
                                }}
                            >
                                Property
                            </TableCell> */}
                            <TableCell
                                sx={{
                                borderBottom: '1px solid #EDF1F3'
                                }}
                            ></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {user?.workflows?.map((row, index) => (
                          <>
                            <TableCell
                              scope="row"
                              sx={{
                                borderBottom: '1px solid #EDF1F3'
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'start',
                                  marginBottom: '1rem',
                                  gap: 1,
                                  alignItems: 'center'
                                }}
                              >
                                <Typography>{row.name}</Typography>
                              </Box>
                            </TableCell>
                            <TableCell sx={{ fontSize: '1rem' }}>{row?.categories?.length ? categories.filter((c: any) => (row?.categories ?? []).indexOf(c?.id) >= 0).map((c) => c.name).join(', ') : 'All'}</TableCell>
                            <TableCell>
                              <ButtonCover onClick={(e) => {
                                setSelectedWorkflow(row);
                                setShowPopup(true);
                              }}>
                                <MoreHorizIcon />
                              </ButtonCover>
                            </TableCell>
                          </>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>

        {showPopup && <CreateUpdatePopup selectedWorkflow={selectedWorkflow} handleClose={setShowPopup} />}
    </>)
}